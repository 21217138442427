/* @flow */

import { translate } from '../service/stringResourceService.js';
import entityManager from '../../common/components/entityManager.js';
import utils, {showInstance} from '../../common/components/utils.js';
import Entity from '../../common/models/entity.js';
import BaseControl from './baseControl.js';
import Constants from '../../common/models/constants'
import OpenMode from '../../common/enums/openMode';
import { stringViewService } from '../../common/service/stringViewService'

type SelectOption = {id: string, text: ?string};

const createSelectData = function (data: Dictionary<string>): Array<SelectOption> {
	const result = [];
	_.each(data, (value, key) => {
		result.push({id: key, text: _.escape(value)});
	});
	return result;
};

const buildLastValue = function(lastValueId, viewId) {
	return lastValueId && {
		id: lastValueId,
		value: entityManager.getStringView(viewId, lastValueId)
	}
}

export const hideCreateAndLinkTypes = [ Constants.ID_TYPE_FOLDER, Constants.ID_TYPE_USER_ACCOUNT, Constants.ID_TYPE_USER_ROLE ]

const FieldSelect = BaseControl.extend({

	events: {
		'select2:open': 'onOpen'
	},

	typeId: null,

	viewId: null,

	ignoreValues: null,

	initialize: function (options) {
		FieldSelect.__super__.initialize.apply(this, arguments);
		this.requestId = 0
		this.typeId = options.typeId || utils.getTypeId(this.$el);
		this.viewId = options.viewId || utils.getViewId(this.$el);
		this.disableCreateNew = options.disableCreateNew || false;
		this.openMode = this.$el.attr('data-use-viewer') || 'new.cj.tab';
		this.createNewAction = this.$el.attr('data-create-new-action') || 'new.cj.tab';
		this.hasCreateNew = this.createNewAction != 'dont.show.create.new' && !hideCreateAndLinkTypes.includes(this.typeId)
		this.hasViewLink = this.openMode != 'dont.show.view.link' && this.openMode != 'dont.open.on.click'
												&& !hideCreateAndLinkTypes.includes(this.typeId)
		this.disabled = this.$el.attr('disabled') == 'disabled'
		this.placeholder = this.$el.attr('placeholder')||null
		this.selectionType = this.$el.attr('data-selection-type') || 'select.component';
		this.parentField = options.parentField;
		this.inTable = this.$el.attr('in-table') == 'true'
		this.skipNotAccessible = this.$el.attr('data-skip-not-accessible') == 'true'
		this.modalOpt = {
			modalWidth: this.$el.attr('data-modal-width'),
			modalHeight: this.$el.attr('data-modal-height'),
			modalFloat: this.$el.attr('data-modal-float')
		}
		this.type = app.types.get(this.typeId)
		this.context = options.context;
		if (this.inTable) {
			this.dataSource = options.dataSource || (this.$el.attr('data-source') &&
				options.model && options.model.get(app.fields.get(this.$el.attr('data-source')).fieldName()));
		} else {
			this.dataSource = options.dataSource || (this.$el.attr('data-source') &&
				options.context && options.context.model.get(app.fields.get(this.$el.attr('data-source')).fieldName()));
		}
		let container = this.$el.parent().children('.container')
		if(container.length == 0) {
			container = $(`<div class='container' style='position:fixed; z-index:100; visibility: initial;'></div>`)
			this.$el.parent().append(container)
		}
		this.dropdownParent = container
		this.initializeSelect2(options);
		_.extend(this.events, FieldSelect.prototype.events);
		this.delegateEvents();
		this.readOnlyText = this.$el.attr('data-read-only-text') == 'true';
		this.$textEl = this.$el.closest('.form-group').find('.read-only-text');
		this.callbackOnEdit = this.model && this.model.get('callbackOnEdit')
		if (app.userObservers && this.context && this.openMode == 'use.on.click.event') {
			this.$el.parent().find('.read-only-text').click(() => {
				let key = (this.parentField && this.parentField + '.' + this.modelAttr) || this.modelAttr
				if (app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key)) {
					app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key).call(this.context, this.context.model, this.model)
				}
			})
		}
		if (this.type && this.type.isExternal() && !this.dataSource) {
			this.selectionType = "nested.window"
		}
	},

	initializeSelect2: function (options) {
		var that = this;
		var $select = that.$el;

		$.fn.select2.amd.require(['select2/data/array', 'select2/utils'],
		function (ArrayData, Utils) {
			function CustomData($element, options) {
				CustomData.__super__.constructor.call(this, $element, options);
			};

			Utils.Extend(CustomData, ArrayData);

			CustomData.prototype.query = function (params, callback) {
				that.loadOptions(params, callback);
			}


			// It is not the first place where Select2() is called.
			// So the original tabindex in <select> tag is already replaced to "-1"
			// Let's restore it from the 'tabindex-orig' attribute.
			const actualValue = $select.attr('tabindex-orig') || $select.attr('tabindex') || '0';
			$select.attr('tabindex', actualValue);
			$select.attr('tabindex-orig', actualValue);

			// Check and set back the focus mode if it is on
			const isFocused = ($select.parent().find('.select2-container--focus').length == 1);
			options.dataAdapter = CustomData;
			$select.select2(that._getSelect2Options(options)).trigger('initialized');

			if (isFocused) {
				$select.select2("focus");
			}
		});
	},

	_getSelect2Options(options) {
		return {
			placeholder: this.placeholder || app.getResource('select.item'),
			ajax: {
				cache: true,
				delay: 500
			},
			allowClear: options.allowClear || !this.$el.attr('data-required'),
			escapeMarkup : (markup) => {
				return markup;
			},
			dropdownParent: this.dropdownParent,
			dataAdapter: options.dataAdapter,
			width: options.width || '100%',
			templateSelection: this.templateSelection.bind(this),
			language: {
				errorLoading: 		() => translate('select2.i18n.errorLoading'),
				inputTooLong: 		() => translate('select2.i18n.inputTooLong'),
				inputTooShort: 		() => translate('select2.i18n.inputTooShort'),
				loadingMore: 			() => translate('select2.i18n.loadingMore'),
				maximumSelected: 	() => translate('select2.i18n.maximumSelected'),
				noResults: 				() => translate('select2.i18n.noResults'),
				searching: 				() => translate('select2.i18n.searching')
			}
		};
	},

	loadOptions: function(params, callback) {
		var that = this;
		params.lastValue = params.lastValue && buildLastValue(params.lastValue.id, that.viewId)

		//TODO::change filters to fieldViewOptions: {order, filters}
		const getData = (filters) => {
			that.filters = filters
			if (that.dataSource) {
				const data = filters ?
					utils.filterCollection(that.dataSource.models, filters) :
					that.dataSource.models;

				Promise.all(
					_.map(data,
						(item) => {
							if (this.type && this.type.isExternal()){
								return entityManager.fetchExternalStringView(item, that.type, that.viewId).then(
									view => {
										return {
											id: item.get("urlString"),
											view: view
										}
									}
									)
							} else {
								return entityManager.fetchStringView(that.viewId, item.id).then(
									view => {
										return {
											id: item.id,
											view: view
										};
									})
							}
						})
				).then(stringViews => {
					// $SuppressFlow
					if (params.term) {
						stringViews = stringViews.filter((elem)=>{
							return elem.view && elem.view.toLowerCase().indexOf(params.term.toLowerCase()) != -1
						})
					}
					stringViews = _.object(_.map(stringViews, _.values));
					const res = {};
					res.results = createSelectData(stringViews, that.typeId, that.viewId);
					res.results = that.filter(res.results);
					callback(res);
				});
			} else if (this.type && !this.type.isExternal() || !this.type){
				var moreStruct = {
					size: app.pageSize,
					lastValue: params.lastValue,
					pattern: params.term,
					filters: filters
					//TODO::add order here
				};
				let requestId = that.requestId + 1
				that.requestId = requestId
				entityManager.fetchPageOfStringViews(that.typeId, that.viewId, moreStruct, that.skipNotAccessible)
					.then(function (data) {
						if (requestId != that.requestId) {
							return
						}
						const res = {};
						let dataArr = Object.keys(data)
						res.results = createSelectData(data, that.typeId, that.viewId);
						res.results = that.filter(res.results);
						res.results = _(res.results).chain().sortBy('id').sortBy('text').value();
						res.pagination = {
							more: dataArr.length === app.pageSize
						};
						let max = dataArr.length ? dataArr[0] : null
						for (let i = 0; i < dataArr.length; ++i) {
							if (dataArr[i] > max) {
								max = dataArr[i]
							}
						}
						if (dataArr.length == app.pageSize && res.results.length < 30) {
							params.lastValue = dataArr.length ? buildLastValue(max, that.viewId) : null
							getData(that.filters)
						} else {
							params.lastValue = res.results.length ? res.results[res.results.length - 1] : null
						}
						callback(res);
					}
				);
			}
		};

		this._getFilters().then(getData)

	},

	_getFilters: function () {
		let key = (this.parentField && this.parentField + '.' + this.modelAttr) || this.modelAttr;
		if (app.userObservers && this.context) {
			return app.userObservers.getFilters(this.context.type.id + ',' + key).call(this.context, this.context.model, this.model);
		} else {
			return Promise.resolve();
		}
	},



	templateSelection: function (state) {
		if (!state.id || !this.hasViewLink) {
			return state.text;
		};
		var $link = $('<a class="select-open-item">')
			.append('<i class="material-icons notranslate">open_in_new</i>');
		let updateEntityUrl = app.urls.update(this.typeId, state.id);
		$link.on('mousedown', (e) => {
			e.stopPropagation()
			this.$el.select2('close') // select should be closed, if not code that prevent parent scroll will persist
			let key = (this.parentField && this.parentField + '.' + this.modelAttr) || this.modelAttr
			if (this.openMode == 'use.on.click.event' && app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key)) {
				app.userObservers.getOnOpenClicked(this.context.type.id + ',' + key).call(this.context, this.context.model, this.model)
			} else {
				showInstance({openMode: this.openMode,
					exactParent: this.model,
					objectId: state.id,
					typeId: this.typeId,
					callback: (data) => {
						if (this.callbackOnEdit) {
							this.callbackOnEdit(data)
						} else {
							data.objectType = {
								id: this.typeId,
							};
							entityManager.evictStringView(data.item.id);
							this.setValue(data.item);
						}
					},
					previousContext: this.context,
					modalOpt: this.modalOpt
				})
			}
		})
		const $showDiv= $('<div>')
		$showDiv.append("<span>"+state.text+"</span>")
		$showDiv.append($link)
		return $showDiv;
	},

	focus: function() {
		this.$el.addClass('select2-container--focus');
	},

	onOpen: function () {
		if (this.selectionType == 'nested.window' &&  app.instanceViewer && app.instanceViewer.canShow()) {
			this._showExtendedView()
			this.$el.select2('close');
			return ;
		}

		var that = this;
		if (this.$el.data('select-width')) {
			let $dropdown = that.$el.parent().find('.select2-dropdown');
			let $select2 = that.$el.parent().find('.select2');
			let diffWidth = $select2.width() - this.$el.data('select-width');
			if(document.dir == 'rtl' && diffWidth < 0) {
				$dropdown.css("left" , diffWidth);
			}
			$dropdown.css("min-width", this.$el.data('select-width'));
		}

		let createNew = this.dropdownParent.find('.select2-results .create-new')
		if (!this.hasCreateNew) {
			if (createNew.length){
				createNew.remove();
			}
		}else{
			if (!createNew.length) {
				var $a = $('<a>')
					.addClass('create-new btn btn-link')
					.html(app.getResource('create.new'));
					this.dropdownParent.find('.select2-results').prepend($a);
				if (this.disableCreateNew) {
					$a.click(function () {
						app.notificationManager.addWarning(translate('not.allowed.in.preview'));
						that.$el.select2('close');
					});
				} else {
					$a.click(() => {
						this._showCreateNew();
						this.$el.select2('close');
					});
				}
			}
		}

		const extendedView = $('.select2-results').find('.extended-view')
		if (this.selectionType != 'select.with.extended.view'
				|| !app.instanceViewer
				|| !app.instanceViewer.canShow()) {
				extendedView.remove();
			} else if (!extendedView.length){
				const $div = $('<div>').prependTo($('.select2-results'));
				const $a = $('<a>')
					.addClass('extended-view btn btn-link')
					.html(app.getResource('extended.view'))
					.appendTo($div);
				$a.click(() => this._showExtendedView());
			}
	},

	_showCreateNew: function() {
		let key = (this.parentField && this.parentField + '.' + this.modelAttr) || this.modelAttr
		if (app.userObservers && this.context && this.createNewAction == 'use.on.create.click.event' && app.userObservers.getOnCreateClicked(this.context.type.id + ',' + key)) {
			app.userObservers.getOnCreateClicked(this.context.type.id + ',' + key).call(this.context, this.context.model, this.model)
		} else {
			let typeId = this.typeId
			showInstance({openMode: this.createNewAction,
				exactParent: this.model,
				typeId: typeId,
				callback: (() => {
					let created = false;
					return (data) => {
						if(created) {
							entityManager.evictStringView(data.item.id);
						}
						data.item.objectType = {
							id: typeId
						};
						created = true
						this.setValue(data.item);
					}
				})(),
				previousContext: this.context,
				modalOpt: this.modalOpt
			})
		}
	},

	_showExtendedView: async function() {
		const filters = await this._getFilters();
		showInstance({
			openMode: OpenMode.NESTED_WINDOW,
			exactParent: this.model,
			isIndex: true,
			typeId: this.typeId,
			clickCallback: (data) => {
				data.objectType = {
					id: this.typeId,
				}
				this.setValue(Entity.fromJSON(data, this.typeId))
				app.instanceViewer.close();
			},
			hideSelection: true,
			canCreate: false,
			hideContextMenu: true,
			filters: filters,
			modalOpt: this.modalOpt
		});
	},

	open: function(){
		this.$el.select2('open');
	},

	filter: function (data: Array<SelectOption>): Array<SelectOption> {
		var that = this;
		if (this.ignoreValues) {
			data = _.filter(data, function (item) {
				var result = true;
				_.each(that.ignoreValues, function (ignore) {
					if (ignore.id == item.id) {
						result = false;
					}
				});
				return result;
			});
		}

		return data;
	},

	setIgnoreValues: function (ignoreValues) {
		this.ignoreValues = ignoreValues;
	},

	_setSelectValue: function (text: string, id: string): void {
		// $SuppressFlow: existing class
		var option = new Option(text, id, true, true);
		this.$el.empty().append(option).val(id).trigger('change');
		if (this.readOnlyText) {
			var updateEntityUrl = app.urls.update(this.typeId, id);
			if (this.hasViewLink){
				this.$textEl.html(`<button type="button" style="padding-left:0px;" class="btn btn-link">${text}</button>`);
				if (this.openMode != 'use.on.click.event') {
					this.$textEl.children().click((event) => showInstance({openMode: this.openMode,
						exactParent: this.model,
						objectId: id,
						typeId: this.typeId,
						callback: (data) => {
							data.item.objectType = {
								id: this.typeId
							};
							if (this.type.isExternal()){
								entityManager.evictStringView(data.item.urlString);
							} else {
								entityManager.evictStringView(data.item.id);
							}
							this.setValue(Entity.fromJSON(data.item, data.item.entityTypeId));
						},
						previousContext: this.context,
						modalOpt: this.modalOpt
					}));
				}
			}else {
				this.$textEl.html(`<span>${text}</span>`);
			}
		}
	},

	render: function() {
	 	BaseControl.prototype.render.call(this);
		// Avoid tooltips when nothing is selected
		if (this.getDataFromModel() == null) {
			this.$el.next().find('.select2-selection__rendered').removeAttr('title');
		}
	},

	setValue: function (value: Object) {
		let ok
		if (this.type && this.type.isExternal()) {
			ok = value && value.get && value.get("urlString") != null
		} else {
			ok = value && value.id != null
		}
		if (ok) {
			let id
			let viewPromise
			if (this.type && this.type.isExternal()) {
				id = value.get("urlString")
				viewPromise = entityManager.fetchExternalStringView(value.toJSON(), this.type, this.viewId)
			} else {
				id = value.id;
				viewPromise = entityManager.fetchStringView(this.viewId, id);
			}
			this._setSelectValue(app.getResource('loading.string.view'), id);
			viewPromise.then(view => {
				view = _.escape(view);

				if ((this.type && this.type.isExternal()) || this.getDataFromModel() && this.getDataFromModel().id == id) {
					this._setSelectValue(view, id);
				}
			}).catch(() => {
				if (this.getDataFromModel() && this.getDataFromModel().id == id) {
					this._setSelectValue(app.getResource('failed.to.load.string.view'), id);
				}
			});
		} else {
			this.$el.val(null).trigger('change');
			if (this.readOnlyText) {
				this.$textEl.html('-');
			}
		}
	},

	getValue: function () {
		return this.$el.val() ? {id: this.$el.val()} : null;
	},

	setTypeAndView: function (typeAndView) {
		this.typeId = typeAndView.typeId;
		this.viewId = typeAndView.viewId;
	},

	getDataFromModel: function () {
		return this.model && this.model.get(this.modelAttr);
	},

	setDataToModel: function (value: EntityReference) {
		let oldId = null
		if (this.type && this.type.isExternal()){
			oldId = this.getDataFromModel() && this.getDataFromModel().get("urlString")
		} else {
			oldId = this.getDataFromModel() && this.getDataFromModel().id
		}
		const newId = value ? value.id : null;
		if (oldId != newId) {
			let entity = null;
			if (this.type && this.type.isExternal()){
				if (this.dataSource){
					entity = this.dataSource.models.find((m) => {
						return m.get("urlString") == newId
					})
				} else {
					entity = Entity.fromJSON({urlString: value.id}, this.typeId)
				}
			} else {
				if (newId != null) {
					entity = Entity.reference(newId, this.typeId);
				}
			}
			this.model && this.model.set(this.modelAttr, entity);
			if (this.model) {
				this.model.trigger('manualChange:' +  this.modelAttr, this.model);
			}
		}
	},

	isDisabledInFormBuilder: function () {
		return this.disabled
	},

	enable: function () {
		this.$el.removeAttr('disabled');
		if (this.readOnlyText) {
			this.$textEl.addClass('hidden');
			this.$el.parent().removeClass('disabled');
		}
	},

	disable: function () {
		this.$el.attr('disabled', 'disabled');
		if (this.readOnlyText) {
			this.$textEl.removeClass('hidden');
			this.$el.parent().addClass('disabled');
		}
	}

});

export default FieldSelect;
