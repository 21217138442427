import { translate } from '../service/stringResourceService'
import utils from "../components/utils";

class UnsavedChangesModal extends Backbone.View {

	initialize () {
		let $el = $('#unsavedChangesModal')
		if ($el.length === 0) {
			let el = document.createElement('div')
			el.id = 'unsavedChangesModal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
		}
		$el = $('#unsavedChangesModal')
		$el.html(this.getHTML())
		this.setElement($el)
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
								<span id="headerText">${translate('unsaved.changes')}</span>
							</h4>
						</div>
						<div class="modal-body">
							<span>${translate('unsaved.changes.text')}</span>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default pull-left cancel" data-dismiss="modal">${translate('cancel')}</button>
							<button type="button" class="btn btn-primary redirect" data-dismiss="modal">${translate('dont.save')}</button>
							<button type="button" class="btn btn-danger saveAndRedirect" data-dismiss="modal"></button>
						</div>
					</div>
				</div>`
	}

	show (options) {
		let continueCallback = options.continueCallback, saveCallback = options.saveCallback;
		let saveText = options.saveText || translate('save.and.continue');
		this.$el
			.find('.saveAndRedirect')
			.text(saveText);
		this.$el
			.find('.cancel')
			.click(() => {
				this.$el.modal('hide');
			});

		this.$el
			.find('.saveAndRedirect')
			.click(() => {
				app.utils.preventPageLeave.save();
				app.tasksQueue.add((e) => {
					saveCallback && saveCallback(e);
					continueCallback && continueCallback(e);
				});
			});

		this.$el
			.find('.redirect')
			.click((e) => {
				app.utils.preventPageLeave.removeEventHandler();
				continueCallback(e);
			});

		this.$el.modal({backdrop: 'static'});
	}

}

export default UnsavedChangesModal;
