/* @flow */

import ListWithFieldSelect from './listWithFieldSelect';
import ReportGroup from '../../common/models/reportGroup';
import ChronoField from '../../time/enums/chronoField';
import MetaObjectTreeViewKind from '../../common/enums/metaObjectTreeViewKind';
import entityManager from '../../common/components/entityManager';
import utils from '../../common/components/utils';
import BaseSelect from '../../common/components/baseSelect'

export default /*ReportGroups*/ ListWithFieldSelect.extend({

	initialize(options) {
		ListWithFieldSelect.prototype.initialize.apply(this, arguments);
	},

	constructItem(field) {
		const maxOrder = this.model.reduce((maxOrder, group) => Math.max(group.get('relativeOrder'), maxOrder), 0);
		return ReportGroup.fromJSON({
			field: {'id': field.id, 'isReference': true},
			relativeOrder: maxOrder + 1
		});
	},

	constructRow(row, group) {
		const field = group.field();

		if (field.type() && field.type().hasMetaObject()) {
			if (field.type().treeViewKind() !== MetaObjectTreeViewKind.NOT_USED) {
				this.initFolderButton(row, group);
			}
		}

		const stringView = $('<div class="row-text"/>');
		stringView.html(utils.stringView(
			entityManager.fetchStringView(null, field.id),
			null, field.id));
		row.append(stringView);

		const toolsDiv = $('<div class="basic-mode report-tools"/>');
		row.append(toolsDiv);
		if (field.type() && field.type().isTemporalAccessor()) {
			const select = $('<select class="form-control temporal-field-select"/>');
			toolsDiv.append(select);
			select.on('change', (e) => {
				group.set('temporalField', select.val());
				let primitiveType = select.val() ? select.val() : this.getFieldPrimitiveType(group)
				let choosenFormatters = this.buildDataForFormatterSelect(primitiveType)
				if (group.get('formatter') && !choosenFormatters.find((f) => { return group.get('formatter').id == f.id })) {
					group.set('formatter', null)
				}
				this.initFormatterSelect(row, group, group.get('temporalField') || this.getFieldPrimitiveType(group))
				this.addFormatterAsTextToRow(group, row)
			});
		}
		this.appendFormatterSelect(toolsDiv, group, false)
	},

	initFolderButton(row, group) {
		const folderButton = $('<i class="material-icons notranslate list-button primary-button" role="button"></i>');
		row.find('.report-list-tools').append(folderButton);

		folderButton.click(() => {
			const includeFolders = folderButton.text() === 'folder_open';
			group.set('includeFolders', includeFolders);
		});
		const onChange = (model, value) => {
			if (value) {
				folderButton.text('folder');
			} else {
				folderButton.text('folder_open');
			}
		};
		group.on('change:includeFolders', onChange);
		onChange(group, group.get('includeFolders'));
	},

	initializeRow(row, group) {
		const field = app.fields.get(group.field().id);

		if (field.type() && field.type().isTemporalAccessor()) {
			const select = row.find('.temporal-field-select');

			const selectData = [];
			_.each(this.visibleTemporalFields, unit => {
				selectData.push({
					id: unit.name(),
					text: unit.getDisplayName()
				});
			});
			select.select2({
				placeholder: 'Temporal Field',
				data: selectData,
				allowClear: true
			});
			select.val(group.get('temporalField')).trigger('change');
		}

		this.initFormatterSelect(row, group, group.get('temporalField') || this.getFieldPrimitiveType(group))
	},

	//TODO: show only supported temporal fields
	visibleTemporalFields: [
		ChronoField.MINUTE_OF_HOUR,
		ChronoField.HOUR_OF_DAY,
		ChronoField.DAY_OF_WEEK,
		ChronoField.DAY_OF_MONTH,
		ChronoField.DAY_OF_YEAR,
		ChronoField.MONTH_OF_YEAR,
		ChronoField.YEAR
	]

});
